<script>
import Horizontal from "./horizontal";
import successModal from "@/components/success.vue";
import ax from '../../helpers/restful/service';
import {
    teacherMainLayoutComputed,
} from "@/state/helpers";

export default {
    components: {
        Horizontal,
        successModal,
    },
    data() {
        return {
            uid: JSON.parse(localStorage.getItem('auth.user')).uid || 0,

            vs_identity: `VS${String(JSON.parse(localStorage.getItem('auth.user')).uid).padStart(6, "0")}`,
            firstname: "",
            lastname: "",
            phone: "",
            email: "",
            line_id: "",
            otp: "",
            verify_otp: "",
            birthday: "",
            national: "",
            origin: "",
            religion: "",
            identity_number: "",
            identity_number_file: "",
            license1: "",
            license1_file: "",
            license2: "",
            license2_file: "",
            license3: "",
            license3_file: "",
            license4: "",
            license4_file: "",
            license5: "",
            license5_file: "",

            address_no: "",
            address_mooban: "",
            address_soi: "",
            address_road: "",
            address_tambon: "",
            address_aumphur: "",
            address_province: "",
            address_postcode: "",

            org_name: "",
            org_institute: "",
            org_institute_file: "",
            org_year_join: "",
            org_year_end: "",
            org_business_name: "",
            org_business_name_file: "",
            org_business_position: "",
            org_status: "",
            org_file: "",
            job_type: "",
            property: "",
            property_file: "",
            property_other: "",

            isShowConfirmModal: false,
            loaded: false,
            loaderHeight: window.innerHeight * 0.1,
            completeAllField: false,
            submittedRegisted: false,
            submittedCheckPrivilege: false,

            attachName: null,
            previewImage: null,
        };
    },
    validations: {

    },
    computed: {
        ...teacherMainLayoutComputed,
    },
    mounted() {
        document.body.classList.add('authentication-bg');
        this.getUserInfo();
    },
    methods: {
        async getUserInfo() {
            console.log("GetUserInfo");
            console.log("UID:", this.uid);

            const resp = await ax.post("/get-latest-user-info", { uid: this.uid });
            console.log("resp:", resp);

            if (resp.status != 200) {
                return;
            }

            this.currentUser = resp.data;
            console.log("currentUser:", this.currentUser);

            if (this.currentUser.teacher_profile != {} && this.currentUser.teacher_profile != undefined && this.currentUser.teacher_profile != null && this.currentUser.teacher_profile.uid >= 0) {
                this.vs_identity = this.currentUser.username || '';
                this.identity_number = this.currentUser.teacher_profile.identity_number || '';
                this.identity_number_file = this.currentUser.teacher_profile.identity_number_file || '';
                this.firstname = this.currentUser.teacher_profile.firstname || '';
                this.lastname = this.currentUser.teacher_profile.lastname || '';
                this.phone = this.currentUser.teacher_profile.phone || '';
                this.line_id = this.currentUser.teacher_profile.line_id || '';
                this.birthday = this.currentUser.teacher_profile.birthday || '';
                this.national = this.currentUser.teacher_profile.national || '';
                this.religion = this.currentUser.teacher_profile.religion || '';
                this.origin = this.currentUser.teacher_profile.origin || '';
                this.license1 = this.currentUser.teacher_profile.license1 || '';
                this.license1_file = this.currentUser.teacher_profile.license1_file || '';
                this.license2 = this.currentUser.teacher_profile.license2 || '';
                this.license2_file = this.currentUser.teacher_profile.license2_file || '';
                this.license3 = this.currentUser.teacher_profile.license3 || '';
                this.license3_file = this.currentUser.teacher_profile.license3_file || '';
                this.license4 = this.currentUser.teacher_profile.license4 || '';
                this.license4_file = this.currentUser.teacher_profile.license4_file || '';
                this.license5 = this.currentUser.teacher_profile.license5 || '';
                this.license5_file = this.currentUser.teacher_profile.license5_file || '';
                this.address_no = this.currentUser.teacher_profile.address_no || '';
                this.address_mooban = this.currentUser.teacher_profile.address_mooban || '';
                this.address_soi = this.currentUser.teacher_profile.address_soi || '';
                this.address_road = this.currentUser.teacher_profile.address_road || '';
                this.address_tambon = this.currentUser.teacher_profile.address_tambon || '';
                this.address_aumphur = this.currentUser.teacher_profile.address_aumphur || '';
                this.address_province = this.currentUser.teacher_profile.address_province || '';
                this.address_postcode = this.currentUser.teacher_profile.address_postcode || '';

                this.org_name = this.currentUser.teacher_profile.org_name || '';
                this.org_institute = this.currentUser.teacher_profile.org_institute || '';
                this.org_institute_file = this.currentUser.teacher_profile.org_institute_file || '';
                this.org_year_join = this.currentUser.teacher_profile.org_year_join || '';
                this.org_year_end = this.currentUser.teacher_profile.org_year_end || '';
                this.org_business_name = this.currentUser.teacher_profile.org_business_name || '';
                this.org_business_name_file = this.currentUser.teacher_profile.org_business_name_file || '';
                this.org_business_position = this.currentUser.teacher_profile.org_business_position || '';
                this.org_status = this.currentUser.teacher_profile.org_status || '';
                this.org_file = this.currentUser.teacher_profile.org_file || '';
                this.job_type = this.currentUser.teacher_profile.job_type || '';
                this.property = this.currentUser.teacher_profile.property || '';
                this.property_file = this.currentUser.teacher_profile.property_file || '';
                this.property_other = this.currentUser.teacher_profile.property_other || '';
            }

            this.loaded = true;

            console.log("loaded:", this.loaded);

        },
        inspectForm() {
            this.isShowConfirmModal = true;
        },
        closeConfirmModal() {
            this.isShowConfirmModal = !this.isShowConfirmModal;
            return;
        },
        attachment(file) {
            console.log(file);
            const input_id = file + "_input";
            this.attachName = file;
            document.getElementById(input_id).click();
        },
        async previewFile(event) {
            console.log("event:", event);
            const [file] = event.target.files;
            this[this.attachName] = URL.createObjectURL(file);
            const filename = await this.uploadFile(file);
            console.log("filename:", filename);
            this[this.attachName] = filename;
        },
        async uploadFile(file) {
            const form = new FormData();
            form.append("file", file);
            const resp = await ax.postForm("/attachment-file", form);
            console.log("resp fileupload:", resp);
            if (resp.file_name) return resp.file_name;
            return null;
        },
        async saveData() {
            const data = {
                uid: this.uid,
                identity_number: this.identity_number,
                identity_number_file: this.identity_number_file,
                firstname: this.firstname,
                lastname: this.lastname,
                phone: this.phone,
                email: this.email,
                line_id: this.line_id,
                birthday: this.birthday,
                national: this.national,
                religion: this.religion,
                origin: this.origin,
                license1: this.license1,
                license1_file: this.license1_file,
                license2: this.license2,
                license2_file: this.license2_file,
                license3: this.license3,
                license3_file: this.license3_file,
                license4: this.license4,
                license4_file: this.license4_file,
                license5: this.license5,
                license5_file: this.license5_file,
                address_no: this.address_no,
                address_mooban: this.address_mooban,
                address_soi: this.address_soi,
                address_road: this.address_road,
                address_tambon: this.address_tambon,
                address_aumphur: this.address_aumphur,
                address_province: this.address_province,
                address_postcode: this.address_postcode,
                org_name: this.org_name,
                org_institute: this.org_institute,
                org_institute_file: this.org_institute_file,
                org_year_join: this.org_year_join,
                org_year_end: this.org_year_end,
                org_business_name: this.org_business_name,
                org_business_name_file: this.org_business_name_file,
                org_business_position: this.org_business_position,
                org_status: this.org_status,
                org_file: this.org_file,
                job_type: this.job_type,
                property: this.property,
                property_file: this.property_file,
                property_other: this.property_other,
            };
            console.log("data:", data);
            const resp = await ax.post("/update-profile", data);
            console.log("resp update:", resp);
            this.isShowConfirmModal = true;
        }
    },
};
</script>

<template>
    <div>
        <Horizontal :layout="layoutType" v-if="loaded">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-start">
                            <div class="my-3">
                                <b-form >
                                    <b-form-group class="mb-3" label="รหัสผู้ใช้ระบบ">
                                        <b-form-input v-model="vs_identity" type="text" placeholder="VSXXXXXX"
                                            :class="{ 'is-invalid': submittedRegisted && $v.vs_identity.$error }"
                                            :readonly="true"></b-form-input>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ชื่อ">
                                        <b-form-input v-model="firstname" type="text" placeholder="ชื่อจริง"
                                            :class="{ 'is-invalid': submittedCheckPrivilege && $v.firstname.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedCheckPrivilege && $v.firstname.$error" class="invalid-feedback">
                                            <div v-if="!$v.firstname.required">ต้องกรอกชื่อ</div>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="นามสกุล">
                                        <b-form-input v-model="lastname" type="text" placeholder="นามสกุลจริง"
                                            :class="{ 'is-invalid': submittedCheckPrivilege && $v.lastname.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedCheckPrivilege && $v.lastname.$error" class="invalid-feedback">
                                            <div v-if="!$v.lastname.required">ต้องกรอกนามสกุล</div>
                                        </div>
                                    </b-form-group>


                                    <b-form-group class="mb-3" label="วัน เดือน ปี เกิด">
                                        <b-form-input v-model="birthday" type="text"
                                            placeholder="วัน เดือน ปี เกิด เช่น 01/01/2500"
                                            :class="{ 'is-invalid': submittedRegisted && $v.birthday.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.birthday.$error" class="invalid-feedback">
                                            <span v-if="!$v.birthday.required">ต้องกรอกวัน เดือน ปี เกิด</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="Line ID">
                                        <b-form-input v-model="line_id" type="text" placeholder="Line ID"
                                            :class="{ 'is-invalid': submittedRegisted && $v.line_id.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.line_id.$error" class="invalid-feedback">
                                            <span v-if="!$v.line_id.required">ต้องกรอก Line ID</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="สัญชาติ">
                                        <b-form-input v-model="national" type="text" placeholder="สัญชาติ"
                                            :class="{ 'is-invalid': submittedRegisted && $v.national.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.national.$error" class="invalid-feedback">
                                            <span v-if="!$v.national.required">ต้องกรอกสัญชาติ</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="เชื้อชาติ">
                                        <b-form-input v-model="origin" type="text" placeholder="เชื้อชาติ"
                                            :class="{ 'is-invalid': submittedRegisted && $v.origin.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.origin.$error" class="invalid-feedback">
                                            <span v-if="!$v.origin.required">ต้องกรอกเชื้อชาติ</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="ศาสนา">
                                        <b-form-input v-model="religion" type="text" placeholder="ศาสนา"
                                            :class="{ 'is-invalid': submittedRegisted && $v.religion.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.religion.$error" class="invalid-feedback">
                                            <span v-if="!$v.religion.required">ต้องกรอกศาสนา</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group class="mb-3" label="เบอร์โทรศัพท์">
                                        <b-form-input v-model="phone" type="text" placeholder="เบอร์โทรศัพท์"
                                            :class="{ 'is-invalid': submittedRegisted && $v.phone.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.phone.$error" class="invalid-feedback">
                                            <span v-if="!$v.phone.required">ต้องกรอกเบอร์โทรศัพท์</span>
                                        </div>
                                    </b-form-group>
                                    
                                    <b-form-group class="mb-3" label="อีเมลล์">
                                        <b-form-input v-model="email" type="text" placeholder="อีเมลล์"
                                            :class="{ 'is-invalid': submittedRegisted && $v.email.$error }"
                                            :readonly="completeAllField"></b-form-input>
                                        <div v-if="submittedRegisted && $v.email.$error" class="invalid-feedback">
                                            <span v-if="!$v.email.required">ต้องกรอกอีเมลล์</span>
                                        </div>
                                    </b-form-group>
                                </b-form>
                            </div>

                        <div class="my-3">
                            <b-form @submit.prevent="saveData()">
                                <div class="mt-3 text-end">
                                    <b-button type="submit" variant="success" class="w-sm" >บันทึกข้อมูล</b-button>
                                </div>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Horizontal>
    <successModal :isShow="isShowConfirmModal" v-on:close="closeConfirmModal"></successModal>
</div></template>
